import React from "react";
import Footers from "../components/footer";
import Headers from "../components/headers";
import Helmetz from "../components/helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import ACard from "../components/awardCard";
import { Card } from "react-bootstrap";

export default function Awards(){
    return(
        <main className=" bg-slate-300">
            <Helmetz />
            <Headers />
            <h1 className="mt-16 pt-4 text-center lg:text-5xl text-4xl font-bold" style={{fontFamily: 'Amatic SC', fontSize: '150'}}>
                My Awards
            </h1>
            <div className="mt-12 mx-auto">
                <Card bg="secondary" text="light" 
                className="text-center place-self-center xl:w-2/5 w-3/4 mx-auto xl:h-52 lg:px-32">
                <Card.Header>National Science Competition</Card.Header>
                    <Card.Body>
                        <Card.Title>Silver Medal in Astronomy</Card.Title>
                        <Card.Text>
                            issued by Indonesian Ministry of Education
                        </Card.Text>
                    </Card.Body>
                <Card.Footer className="text-zinc-300">November 2021</Card.Footer>
                </Card>
            </div>
            <div className="content-evenly grid grid-cols-1 xl:grid-cols-2 grid-flow-row mt-12 lg:mx-32">
                <div className="place-self-center pb-8 w-full">
                    <ACard bg="primary" textcolor="white"
                    header="Pekan Raya Fisika Nasional VI"
                    title="Bronze Medal in OASIS (Astronomy and Astrophysics)"
                    text="issued by Himpunan Mahasiswa Fisika Universitas Riau"
                    date="March 2021"
                    />
                </div>
                <div className="place-self-center pb-8 w-full">
                    <ACard bg="warning" textcolor="white"
                    header="Champ Competition"
                    title="First Place in Astronomy"
                    text="issued by Yayasan BPK PENABUR"
                    date="March 2021"
                    />
                </div>
                
                <div className="place-self-center pb-8 w-full">
                    <ACard bg="dark" textcolor="white"
                    header="American Mathematics Olympiad (AMO)"
                    title="Bronze Medal in Grade 09 Division"
                    text="issued by American Mathematics Olympiad"
                    date="2019"
                    />
                </div>
                <div className="place-self-center pb-8 w-full">
                    <ACard bg="dark" textcolor="white"
                    header="Singapore and Asian Schools Math Olympiad (SASMO)"
                    title="Bronze Medal in Grade 08 Division"
                    text="issued by Singapore and Asian Schools Math Olympiad"
                    date="May 2019"
                    />
                </div>
            </div>
            <Footers />
        </main>
    )
}