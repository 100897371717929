import React from "react";
import { Card } from 'react-bootstrap';

export default function ACard(props){
    return(
        <Card bg={props.bg} text={props.textcolor}
                className="text-center place-self-center w-3/4 xl:h-52 mx-auto">
                <Card.Header>{props.header}</Card.Header>
                    <Card.Body>
                        <Card.Title>{props.title}</Card.Title>
                        <Card.Text>
                            {props.text}
                        </Card.Text>
                    </Card.Body>
                <Card.Footer className="text-zinc-300">{props.date}</Card.Footer>
        </Card>
    )
}